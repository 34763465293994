import { useEffect, useCallback } from 'react';
import useUser from '@/hooks/useUser';
import track from 'react-tracking';
import loadable from '@loadable/component';
import { useUserAccess } from '@/hooks/useUserAccess';
import { useModalManagement } from '@/components/ModalManagement';

const PlansModal = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '@/components/OurPlans/components/PlansModal/PlansModal'
    ),
  { ssr: false }
);

const PlansModalWithTracking = track({
  type: 'track',
  action: 'shown',
  modifier: 'plansModal',
  page: 'trialExpired',
})(PlansModal) as typeof PlansModal;

const TrialEndedPlansModal = () => {
  const { data: access } = useUserAccess();
  const { data: user, mutate: mutateUser, isFetched } = useUser();

  const { isShowingModal, addModalIfEmpty, removeModal } = useModalManagement(
    'trialEndedPlansModal'
  );

  useEffect(() => {
    if (
      isFetched &&
      user.hasSeenTrialEndModal !== true &&
      access.planType === 'free' &&
      user.billing.status !== 'trialing' &&
      user.billing.status !== 'past_due' &&
      RUNTIME_ENV !== 'server'
    ) {
      addModalIfEmpty();
    }
  }, [isFetched, access, user]);

  const setTrialEndModalShown = useCallback(() => {
    mutateUser({
      settings: {
        has_seen_trial_end_modal: true,
      },
    });
  }, [mutateUser]);

  return (
    <>
      {isShowingModal && (
        <PlansModalWithTracking
          onClose={removeModal}
          onAction={setTrialEndModalShown}
        >
          <p data-cy-id="plan-restriction">
            {'Premium Trial expired.'}
            {RUNTIME_ENV !== 'native' && ' You’re now on our Free Plan.'}
          </p>

          <h2>
            {RUNTIME_ENV === 'native'
              ? 'More stock analysis can be accessed via our other plans.'
              : 'Select the plan that best suits your needs to continue.'}
          </h2>
        </PlansModalWithTracking>
      )}
    </>
  );
};
export default TrialEndedPlansModal;
